import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  constinerFluid: {
    padding: [[26, 0, 30]],
    width: '100%',
  },
  container: {
    textAlign: 'center',
  },
  boxButton: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0.4rem',
    marginBottom: '6rem',
    alignItems: 'flex-start',
    padding: '0 2rem',
  },
  content: {
    paddingBottom: '3rem',
    '& p': {
      fontSize: '1.6rem',
      margin: 0,
      textAlign: 'left',
      lineHeight: '2rem',
      '&:first-child': {
        fontWeight: 600,
      },
      '&:last-child': {
        color: theme.colors.bgMediumDark,
      },
    },
  },
  button: {
    minWidth: '23rem',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    containerFluid: {
      padding: [[60, 10]],
      width: '100%',
      textAlign: 'center',
    },
    container: {
      width: 1170,
      minHeight: '60rem',
      margin: [[0, 'auto']],
      display: 'flex',
      justifyContent: 'start',
      flexDirection: 'column',
    },
    boxButton: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '6.5rem',
      marginBottom: '5rem',
      alignItems: 'center',
    },
  },
}))

export default useStyles
