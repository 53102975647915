/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './account-my-coupons-style'
import CouponsTabs from './coupon-tabs/coupon-tabs-view'
import { Button, Layout, SEO } from '../../../ui'

function AccountMyCouponsView(props) {
  const {
    seoTitle,
    onCouponMarketplaceOpen,
  } = props
  const styles = useStyles()
  const { t } = useTranslation()
  return (
    <Layout login>
      <SEO title={seoTitle} />
      <div className={styles.constinerFluid}>
        <div className={styles.container}>
          <div className={styles.boxButton}>
            <div className={styles.content}>
              <p>{t('screens.account.myCoupons.rewars')}</p>
              <p>{t('screens.account.myCoupons.message')}</p>
            </div>
            <div>
              <Button
                text={t('screens.account.myCoupons.button')}
                dark
                onClick={onCouponMarketplaceOpen}
                className={styles.button}
              />
            </div>
          </div>
          <CouponsTabs {...props} />
        </div>
      </div>
    </Layout>
  )
}

export default AccountMyCouponsView
