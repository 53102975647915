/* eslint-disable react/jsx-boolean-value */
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs'
import { orderMonths } from '../../../../helpers'
import useStyles from './coupon-tabs-style'
import {
  CouponMonthBlock, CouponCard, MessageScreen, Spinner, StateLabel,
} from '../../../../ui'

const CouponsTabs = (props) => {
  const {
    couponUsages,
    hasMorCouponUsages,
    hasMorLoyaltyPointTransactions,
    isCouponUsagesReady,
    isLoyaltyPointTransactionsReady,
    loyaltyPointTransactions,
    onFetchCouponUsagesNextPage,
    onFetchLoyaltyPointTransactionsNextPage,
  } = props
  const { t } = useTranslation()
  const style = useStyles()
  const [pointsOrdByMonths, setPointsOrdByMonths] = useState([])
  const [couponsOrdByMonths, setCouponsOrdByMonths] = useState([])

  function getCouponUsageToItemDate(data = {}) {
    const {
      createdAt,
      couponToken,
    } = data
    const {
      coupon,
    } = couponToken
    const {
      description,
      discountType,
      discountValue,
      name,
    } = coupon || {}
    const displayTime = `${moment(createdAt).format('DD.MM.YYYY HH:mm')}`

    return {
      // TODO: add ecomSmall in API image config
      imageUrl: _.get(coupon, 'images[0].versions.webSmall'),
      title: name,
      discountType,
      discountValue,
      description,
      displayTime,
      state: 'disabled',
      stateLabel: t('screens.account.myCoupons.couponUsage.used'),
    }
  }

  function getLoyaltyPointToItemData(data = {}) {
    const {
      activeAtLabel,
      activeAt,
      codeLabel,
      couponToken,
      points,
    } = data
    const displayTime = `${moment(activeAt).format('DD.MM.YYYY HH:mm')}`

    let state = ''
    let pointSign = ''
    switch (true) {
      case points > 0:
        state = 'success'
        pointSign = 'positive'
        break
      case points < 0:
        state = 'error'
        pointSign = 'negative'
        break
      case points === 0:
      default:
        state = 'disabled'
        break
    }
    return {
      title: _.get(couponToken, 'coupon.name', codeLabel),
      description: activeAtLabel,
      displayTime,
      state,
      stateLabel: t('screens.account.myCoupons.transactionPoint', {
        context: pointSign,
        points: Math.abs(points),
      }),
    }
  }

  useEffect(() => {
    if (!_.isEmpty(loyaltyPointTransactions)) {
      const monthsAfterOrderred = orderMonths(loyaltyPointTransactions, 'activeAt')
      setPointsOrdByMonths(monthsAfterOrderred)
    }
  }, [loyaltyPointTransactions])

  useEffect(() => {
    if (!_.isEmpty(couponUsages)) {
      const couponsByMonth = orderMonths(couponUsages, 'createdAt')
      setCouponsOrdByMonths(couponsByMonth)
    }
  }, [couponUsages])

  return (
    <Tabs className={style.tabContainer}>
      <TabList className={style.couponsTabs}>
        <Tab className={style.couponsTab}>{t('screens.account.coupons.points')}</Tab>
        <Tab className={style.couponsTab}>{t('screens.account.coupons.coupon')}</Tab>
      </TabList>
      <TabPanel className={style.couponsPanel}>
        {
          isLoyaltyPointTransactionsReady
            ? (
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={onFetchLoyaltyPointTransactionsNextPage}
                hasMore={hasMorLoyaltyPointTransactions}
                loader={<Spinner />}
                useWindow={true}
              >
                {
                  pointsOrdByMonths.length > 0 ? (
                    pointsOrdByMonths.map((group) => {
                      const sectionHeaderText = moment(group.month).format('MMMM YYYY')
                      return (
                        <CouponMonthBlock
                          key={group.month}
                          sectionHeaderText={sectionHeaderText}
                        >
                          {
                            group.options.map((data) => {
                              const {
                                title,
                                description,
                                displayTime,
                                state,
                                stateLabel,
                              } = getLoyaltyPointToItemData(data)
                              return (
                                <CouponCard
                                  key={`loyalty-point-transaction-${data.id}`}
                                  type="point"
                                  id={data.id}
                                  title={title}
                                  description={description}
                                  displayTime={displayTime}
                                  state={state}
                                  stateLabel={stateLabel}
                                />
                              )
                            })
                          }
                        </CouponMonthBlock>
                      )
                    })
                  )
                    : (
                      <div className={style.noContent}>
                        <MessageScreen
                          message={t('screens.account.coupons.message')}
                          button={{
                            text: t('screens.account.coupons.button'),
                            navigate: '/',
                          }}
                        />
                      </div>
                    )
                }
              </InfiniteScroll>
            )
            : null
        }
      </TabPanel>
      <TabPanel>
        {
          isCouponUsagesReady
            ? (
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={onFetchCouponUsagesNextPage}
                hasMore={hasMorCouponUsages}
                loader={<Spinner />}
                useWindow={true}
              >
                {
                  couponsOrdByMonths.length > 0
                    ? (
                      couponsOrdByMonths.map((group) => {
                        const sectionHeaderText = moment(group.month).format('MMMM YYYY')
                        return (
                          <CouponMonthBlock
                            key={group.month}
                            sectionHeaderText={sectionHeaderText}
                          >
                            {
                              group.options.map((data) => {
                                const {
                                  title,
                                  discountType,
                                  discountValue,
                                  description,
                                  displayTime,
                                  imageUrl,
                                  state,
                                  stateLabel,
                                } = getCouponUsageToItemDate(data)
                                return (
                                  <CouponCard
                                    // miniCartCoupon
                                    key={`coupon-usage-${data.id}`}
                                    type="couponUsage"
                                    id={data.id}
                                    displayImage
                                    imageUrl={imageUrl}
                                    title={title}
                                    discountType={discountType}
                                    discountValue={discountValue}
                                    description={description}
                                    displayTime={displayTime}
                                    state={state}
                                    stateLabel={
                                      <StateLabel state={state}>{stateLabel}</StateLabel>
                                    }
                                  />
                                )
                              })
                            }
                          </CouponMonthBlock>
                        )
                      })
                    )
                    : (
                      <div className={style.noContent}>
                        <MessageScreen
                          message={t('screens.account.coupons.messageCoupons')}
                          button={{
                            text: t('screens.account.coupons.button'),
                            navigate: '/',
                          }}
                        />
                      </div>
                    )
                }
              </InfiniteScroll>
            )
            : null
        }

      </TabPanel>
    </Tabs>
  )
}

export default CouponsTabs
