/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useEffect } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  cancelRequest,
  useAuth,
  useCouponUsages,
  useLoyaltyPointTransactions,
} from 'react-omnitech-api'
import { useLink, useCouponMarketplace } from '../../../hook'
import AccountMyCouponsView from './account-my-coupons-view'

function AccountMyCouponsController() {
  // prepare hook
  const { auth } = useAuth()
  const { fetchCouponUsages } = useCouponUsages()
  const { navigate } = useLink()
  const { t } = useTranslation()
  const { fetchLoyaltyPointTransactions } = useLoyaltyPointTransactions()
  const { onCouponMarketplaceOpen } = useCouponMarketplace()

  // state
  const [couponUsages, setCouponUsages] = useState([])
  const [loyaltyPointTransactions, setLoyaltyPointTransactions] = useState([])
  const [hasMorCouponUsages, setHasMorCouponUsages] = useState(false)
  const [hasMorLoyaltyPointTransactions, setHasMorLoyaltyPointTransactions] = useState(false)
  const [fetchNextCouponUsages, setFetchNextCouponUsages] = useState(null)
  const [fetchNextLoyaltyPointTransactions, setFetchNextLoyaltyPointTransactions] = useState(null)
  const [isCouponUsagesReady, setIsCouponUsagesReady] = useState(false)
  const [isLoyaltyPointTransactionsReady, setIsLoyaltyPointTransactionsReady] = useState(false)
  const [pageReady, setPageReady] = useState(false)

  // local variable
  const seoTitle = t('screens.accountMyCoupons.seo.title')

  const handleFetchCouponUsages = useCallback(async (fetchNextPage) => {
    try {
      // api call option
      const options = {
        includes: [
          'coupon_token',
          'coupon_tokens.coupon',
          'coupons.images',
        ].join(','),
        pageSize: 5,
      }
      // call api
      const { couponUsages: data, next } = _.isFunction(fetchNextPage)
        ? await fetchNextPage()
        : await fetchCouponUsages(options)

      // update state
      setCouponUsages((prevState) => prevState.concat(data))
      setHasMorCouponUsages(_.isFunction(next))
      setFetchNextCouponUsages(() => next)
      if (_.isEmpty(fetchNextPage)) {
        setIsCouponUsagesReady(true)
      }
    } catch (error) {
      // TODO: when fail to load coupons point, what should be shown?
    }
  }, [fetchCouponUsages])

  function handleFetchCouponUsagesNextPage() {
    if (!_.isFunction(fetchNextCouponUsages)) return

    handleFetchCouponUsages(fetchNextCouponUsages)
  }

  const handleFetchLoyaltyPointTransactions = useCallback(async (fetchNextPage) => {
    try {
      // api call option
      const options = {
        includes: [
          'coupon_token',
          'coupon_tokens.coupon',
        ].join(','),
        pageSize: 5,
      }
      // call api
      const { loyaltyPointTransactions: data, next } = _.isFunction(fetchNextPage)
        ? await fetchNextPage()
        : await fetchLoyaltyPointTransactions(options)

      // update state
      setLoyaltyPointTransactions((prevState) => prevState.concat(data))
      setHasMorLoyaltyPointTransactions(_.isFunction(next))
      setFetchNextLoyaltyPointTransactions(() => next)
      if (_.isEmpty(fetchNextPage)) {
        setIsLoyaltyPointTransactionsReady(true)
      }
    } catch (error) {
      // TODO: when fail to load coupons point, what should be shown?
    } finally {
      setPageReady(true)
    }
  }, [fetchLoyaltyPointTransactions])

  function handleFetchLoyaltyPointTransactionsNextPage() {
    if (!_.isFunction(fetchNextLoyaltyPointTransactions)) return

    handleFetchLoyaltyPointTransactions(fetchNextLoyaltyPointTransactions)
  }

  /**
   * when the page is loaded
   * without user logged in, redirect to login page
   */
  useEffect(() => {
    if (!auth.userId) {
      navigate(
        '/login/',
        {
          state: {
            redirectUrl: '/account/my-coupons/',
          },
          replace: true,
        },
      )
    }
  }, [])

  useEffect(() => {
    handleFetchCouponUsages()
    return () => {
      cancelRequest.cancelAll(['fetchCouponUsages'])
    }
  }, [handleFetchCouponUsages])

  useEffect(() => {
    handleFetchLoyaltyPointTransactions()
    return () => {
      cancelRequest.cancelAll(['fetchLoyaltyPointTransactions'])
    }
  }, [handleFetchLoyaltyPointTransactions])

  const viewProps = {
    couponUsages,
    loyaltyPointTransactions,
    hasMorCouponUsages,
    hasMorLoyaltyPointTransactions,
    isCouponUsagesReady,
    isLoyaltyPointTransactionsReady,
    pageReady,
    seoTitle,
    onCouponMarketplaceOpen,
    onFetchCouponUsagesNextPage: handleFetchCouponUsagesNextPage,
    onFetchLoyaltyPointTransactionsNextPage: handleFetchLoyaltyPointTransactionsNextPage,
  }

  return (
    <AccountMyCouponsView {...viewProps} />
  )
}

export default AccountMyCouponsController
