import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  tabContainer: {
    textAlign: 'center',
    width: '100%',
    margin: ' 0 auto',
  },
  couponsTabs: {
    display: 'flex',
    justifyContent: 'space-around',
    listStyle: 'none',
    margin: [0, '1.687rem'],
  },
  couponsTab: {
    position: 'relative',
    fontSize: '1.6rem',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: theme.colors.disabled,
    cursor: 'pointer',
    '&.react-tabs__tab--selected': {
      color: theme.colors.primary,
      '&:after': {
        content: '""',
        position: 'absolute',
        height: 3,
        width: '100%',
        backgroundColor: theme.colors.secondary,
        left: 0,
        bottom: '-12px',
      },
    },
  },
  couponsPanel: {
    marginTop: '3.2rem',
  },
  noContent: {
    marginTop: '10rem',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    tabContainer: {
      maxWidth: '57rem',
    },
    couponsTabs: {
      marginTop: '2.4rem',
    },
  },
}))

export default useStyles
